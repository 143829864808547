
import fetch, {
  Headers,
  Request,
  Response,
} from 'node-fetch'
import * as Sentry from "@sentry/react";
import { AddReactComponent } from './helper';
import SslCheckerForm from './sslChecker/sslCheckerForm';

if (!globalThis.fetch) {
  Object.assign(globalThis, { fetch, Headers, Request, Response });
}

// Sentry tracking
Sentry.init({
  dsn: "https://89b51f99a1208291281e73dd849b1e9c@o4507906549153792.ingest.de.sentry.io/4507906563637328",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false
    }),
  ],    
  // Performance Monitoring
  allowUrls: [/^https:\/\/www\.sslspy\.com(\/.*)?$/ig],
  tracesSampleRate: 0.2, // Capture 20% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.01, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0,    
});

// Add React components
AddReactComponent("[data-ssl-checker-form-container]", <SslCheckerForm />);