import React, { useContext, useState } from "react";
import { GenerateRandomString } from "../helper";
import { IFieldProperties } from "./fieldProperties";
import { FormContext } from "./form";

export class TextBoxProperties implements IFieldProperties {
    name: string;
    id: string;
    label: string;
    prefixLabel?: string;
    placeholder: string;
    htmlTag: string;
    className: string;
    value?: string;
    defaultValue?: string;
    valid?: boolean;
    required: boolean;
    minLength?: number;
    maxLength?: number;
    pattern?: RegExp;

    requiredErrorMessage?: JSX.Element;
    minErrorMessage?: JSX.Element;
    maxErrorMessage?: JSX.Element;
    patternErrorText?: JSX.Element;


    errorMessage?: JSX.Element;
    onValidated?: (() => void);
    onValidChange?: (() => void);
    onResetValue?: (() => void);
    onRefreshComponentState?: (() => void);

    constructor(fields: { name: string, id: string, label: string, prefixLabel?: string, placeholder: string, htmlTag: string, className: string, defaultValue?: string, required?: boolean, minLength?: number, maxLength?: number, pattern?: RegExp, requiredErrorMessage?: JSX.Element,
        minErrorMessage?: JSX.Element, maxErrorMessage?: JSX.Element, patternErrorText?: JSX.Element }) {
        this.id = GenerateRandomString(8) + "-" + fields.id;
        this.name = fields.name;
        this.label = fields.label; 
        this.prefixLabel = fields.prefixLabel;    
        this.placeholder = fields.placeholder;
        this.className = fields.className; 
        this.htmlTag = fields.htmlTag; 
        this.value = fields.defaultValue;
        this.defaultValue = fields.defaultValue;
        this.required = fields.required ?? false;
        this.minLength = fields.minLength;
        this.maxLength = fields.maxLength;
        this.pattern = fields.pattern;
        this.requiredErrorMessage = fields.requiredErrorMessage;
        this.minErrorMessage = fields.minErrorMessage;
        this.maxErrorMessage = fields.maxErrorMessage;
        this.patternErrorText = fields.patternErrorText;
    }
    setErrorMessage(errorMessage: JSX.Element): void {
        this.valid = false;
        this.errorMessage = errorMessage;

        if (this.onValidated) {
            this.onValidated();
        }
    }

    resetValue() {
        this.value = '';
        this.valid = undefined;
        this.errorMessage = undefined;

        if (this.onResetValue) {
            this.onResetValue();
        }
    }

    validate(): void {
        var originalValid = this.valid;

        this.valid = true;
        this.errorMessage = undefined;

        if (this.required && !this.value) {
            // Required
            this.valid = false;
            this.errorMessage = this.requiredErrorMessage;
        }
        if (this.value) {
            if (this.minLength && this.value.length < this.minLength) {
                // Minimum length
                this.valid = false;
                this.errorMessage = this.minErrorMessage;
            }
            if (this.maxLength && this.value.length > this.maxLength) {
                // Maximum length
                this.valid = false;
                this.errorMessage = this.maxErrorMessage;
            } 
            if (this.pattern) {
                var pattern = new RegExp(this.pattern);

                if (!pattern.test(this.value)) {
                    this.valid = false;
                    this.errorMessage = this.patternErrorText;
                }                  
            }
        }       
        
        if (this.onValidated) {
            this.onValidated();
        }

        if (this.onValidChange && originalValid !== undefined && originalValid != this.valid) {
            // Revalidate form if the valid status has changed.
            this.onValidChange();
        }
    }
    
    refreshComponentState(): void {
        this.valid = undefined;
        this.errorMessage = undefined;

        if (this.onRefreshComponentState) {
            this.onRefreshComponentState();
        }
    }    
}


const TextBox: React.FC<{name: string}> = (properties) => {

    const formContext = useContext(FormContext);    
    var textBoxProperties = formContext?.findFieldProperties<TextBoxProperties>(properties.name);

    const [state, setState] = useState<{value?: string, valid?: boolean, errorMessage?: JSX.Element}>({
        value: textBoxProperties?.value?.toString(),
        valid: textBoxProperties?.valid,
        errorMessage: undefined
    });

    if (!textBoxProperties) {
        return <></>
    }

    const onTextChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {   
        if (textBoxProperties) {
            textBoxProperties.value = event.target.value;

            if (textBoxProperties.valid !== undefined) {
                // Already been validated, so revalid text box. This will call textBoxProperties.onValidated
                textBoxProperties.validate();
            }
            else {
                // Set the value of the textbox only.
                setState({ value: textBoxProperties.value?.toString(), valid: textBoxProperties.valid, errorMessage: textBoxProperties.errorMessage });
            }
        }
    };
    textBoxProperties.onValidated = () => {
        if (textBoxProperties) {
            setState({ value: textBoxProperties.value, valid: textBoxProperties.valid, errorMessage: textBoxProperties.errorMessage });
        }
    }
    textBoxProperties.onResetValue = () => {
        if (textBoxProperties) {
            setState({ value: textBoxProperties.value, valid: textBoxProperties.valid, errorMessage: textBoxProperties.errorMessage });
        }
    }  
    textBoxProperties.onRefreshComponentState = () => {
        if (textBoxProperties) {
            setState({ value: textBoxProperties.value, valid: textBoxProperties.valid, errorMessage: textBoxProperties.errorMessage });
        }        
    }  

    var className = "form-control" + (textBoxProperties.valid === false ? " error" : "")

    return <div className={textBoxProperties.className}>
        <label htmlFor={textBoxProperties.id}>{textBoxProperties.label}</label>      
        {textBoxProperties.htmlTag == "input" && 
            <div className="form-element">  
            {textBoxProperties.prefixLabel &&
                <span className="prefix">{textBoxProperties.prefixLabel}</span>
            }
            <input type="text" className={className} value={state.value} onChange={onTextChange} placeholder={textBoxProperties.placeholder} required={textBoxProperties.required} />
            </div>
        }
        {textBoxProperties.htmlTag == "textarea" &&  
            <div className="form-element">  
                <textarea className={className} value={state.value} onChange={onTextChange} placeholder={textBoxProperties.placeholder} required={textBoxProperties.required}></textarea>
            </div>
        }
        {state.valid === false &&
            <span className="error-message">
                <>{state.errorMessage}</>
            </span>
        }
    </div>
}
export { TextBox };