import { createRoot } from 'react-dom/client';
import * as Sentry from "@sentry/react";

const AddClass = (element: HTMLElement, className: string): void => {
    if (!element) {
        return;
    }

    if (!element.classList.contains(className)) {
        element.classList.add(className);
    }   
}

const RemoveClass = (element: HTMLElement, className: string): void => {
    if (!element) {
        return;
    }

    if (element.classList.contains(className)) {
        element.classList.remove(className);
    }   
}

const CaptureSentryException = (message: string) => {
    try {
      throw message;
    }
    catch (error) {
        Sentry.captureException(error);
    }
  }

const GenerateRandomString = (length: number): string => {
    let characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

    let result = '';
    let charactersLength = characters.length;
    for (var tt =1; tt<= length; tt++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
}

const AddReactComponent = (selector: string, component: React.FunctionComponentElement<any> | React.FunctionComponentElement<any>[]) => {
    if (document.querySelector(selector)) {
        const root = createRoot(document.querySelector(selector)!);
        root.render(component);
    }
}

export { AddClass, RemoveClass, GenerateRandomString, CaptureSentryException, AddReactComponent }