const TrackGoogleAnalyticsEvent = (eventName: string, params?: any, eventCallback?: (() => void) | undefined) => {
    if (!params) {
        params = {};
    }

    if (eventCallback) {
        params = { 
            ...params, 
            'event_callback': eventCallback,
            'event_timeout': 2000
        }
    }

    if (window.gtag) {
        window.gtag('event', eventName, params);
    }
    else {
        if (eventCallback) {
            eventCallback();
        }
    }
}

export { TrackGoogleAnalyticsEvent }