import React from "react";
import Form from "../form/form";
import { TextBox, TextBoxProperties } from "../form/textBox";
import { TrackGoogleAnalyticsEvent } from "../analytics/ga4Tracking";

const SslCheckerForm: React.FC = () => {

    var website = new TextBoxProperties({ name: "website", id: "ssl-checker-form-website", label: "Website", prefixLabel: "https://", placeholder: "Please enter the website", htmlTag: "input", className: "form-group col-full", required: true, minLength:3, maxLength:300, pattern: /^([a-zA-Z0-9]+(-[a-zA-Z0-9]+)*\.)+[a-z]{2,6}$/ig, requiredErrorMessage: <>Please enter a website</>, patternErrorText: <>Please enter a valid website domain with letters, numbers, hypthens (-) and dots (.)</>, minErrorMessage: <>Please enter a valid website domain with letters, numbers, hypthens (-) and dots (.)</>, maxErrorMessage: <>Please enter a valid website domain with letters, numbers, hypthens (-) and dots (.)</> });

    return <> 
    <div className="form-component-section">
        <div className="title-section">
            <span>SSL certificate expiry checker</span>
        </div>
        <div className="form-section">
            <div className="description-section">
                <p>Please enter the website below to check if it has an SSL certificate installed and when it's due to expire.</p>
            </div>
            <Form fieldProperties={
                [
                    website
                ]
            } container={
                <>
                    <TextBox name="website"></TextBox>
                </>
            } 
            submitApiEndpoint="/api/ssl-checker/create" 
            submitLabel="Check SSL expiry"
            onSubmitCallback={(json) => {
                TrackGoogleAnalyticsEvent("ssl_certificate_expiry_checker_form_submit", { website: json["website"] });
            }}
            >            
            </Form>
        </div>
    </div>
    </>
}

export default SslCheckerForm;